import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import VideoPlayer from '../../containers/video-player';
import SCORMPlayer from '../../containers/scorm-player';
import Question from './question';
import { Container } from 'reactstrap';
import { formatTime } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import {
    FlexDiv,
    Heading1,
    palette,
    TextLead,
    TextTiny,
    StatusBadge,
    Heading4,
    Heading3,
} from '@awarego/awarego-components';
import DecisionFeedbackTable from './DecisionFeedbackTable';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const CONSTANTS = require('../../utils/constants');

const useStyles = makeStyles((theme) => ({
    subject: (props) => {
        if (props.width && props.type === 2) {
            return {
                maxWidth: props.width,
            };
        }
        return {
            padding: '0px 64px',
            [theme.breakpoints.down(576)]: {
                padding: '0px 8px',
            },
            [theme.breakpoints.up(992)]: {
                maxWidth: 864,
                paddingLeft: 0,
            },
        };
    },
}));

export default observer(
    ({
        training,
        subject,
        logEvent,
        shuffledAnswers,
        step2Available,
        questionsAvailable,
        videoTokenStore,
    }) => {
        const [decisionsCompleted, setDecisionsCompleted] = useState(false);
        const [decisionFeedbackTable, setDecisionFeedbackTable] = useState([]);
        const { t } = useTranslation();
        const scrollRef = React.createRef();
        const classes = useStyles({
            width: subject && subject.definition && subject.definition.width,
            type: subject && subject.type,
        });
        const scrollToMyRef = () => {
            if (scrollRef.current) {
                scrollRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        };

        useEffect(() => {
            setDecisionsCompleted(subject.hasBeenTried && !subject.started);
            setDecisionFeedbackTable(
                subject.hasBeenTried && subject.decisionFeedbackTable
            );
        }, [subject]);

        useEffect(() => {
            if (step2Available) {
                scrollToMyRef();
            }
        }, [step2Available]);

        const { storedDecisions, numberOfRightAnswers } = subject;

        const onDecisionMade = async (decision) => {
            await logEvent(subject, CONSTANTS.EVENTS.DECISION, {
                decision: decision.key,
                result: !!decision.correct,
                next: decision.playlistIndex,
            });

            if (!subject.video_decisions[decision.playlistIndex].questionText) {
                const { numberOfRightAnswers, numberOfPossibleDecisions } =
                    subject;
                numberOfRightAnswers / numberOfPossibleDecisions === 1
                    ? logEvent(
                          subject,
                          CONSTANTS.EVENTS.ANSWARE_CORRECT,
                          storedDecisions
                      )
                    : logEvent(
                          subject,
                          CONSTANTS.EVENTS.ANSWARE_INCORRECT,
                          storedDecisions
                      );

                setDecisionFeedbackTable(subject.decisionFeedbackTable);
            }
        };

        const onLastDecisionMade = (decision) => {
            setDecisionsCompleted(true);
            scrollToMyRef();
            if (decision.previewed) {
                logEvent(subject, CONSTANTS.EVENTS.ANSWARE_CORRECT, {
                    previewed: true,
                });
            }
        };

        if (!subject) {
            return null;
        }

        return (
            <div>
                <Helmet>
                    <title>{subject.title}</title>
                </Helmet>
                <div className={clsx('subject', classes.subject)}>
                    {subject.type !== 2 && (
                        <FlexDiv column mt={24} gap={24}>
                            <FlexDiv column>
                                <Heading1>{subject.title}</Heading1>
                                <FlexDiv gap={16} alignCenter>
                                    <TextTiny bold lighter>
                                        {formatTime(subject.duration)}{' '}
                                        {t('min')}
                                    </TextTiny>
                                    {subject.type === 3 && (
                                        <StatusBadge
                                            text={t(
                                                'decisionSubjectInteractiveBadge'
                                            )}
                                            color={palette.graphite.heavyMetal}
                                            thinner
                                        />
                                    )}
                                </FlexDiv>
                            </FlexDiv>
                            <TextLead lighter>{subject.description}</TextLead>
                        </FlexDiv>
                    )}
                    {subject.notes && (
                        <div className="custom-note">
                            {subject.notes &&
                                subject.notes.map((note) => {
                                    return (
                                        <div
                                            className="subject-optional-note"
                                            key={note.id}
                                        >
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtml(
                                                        note.content
                                                    ),
                                                }}
                                            ></div>
                                        </div>
                                    );
                                })}
                        </div>
                    )}

                    {subject.type === 1 && (
                        <Fragment>
                            <Heading3 mt={32} mb={16} displayBlock>
                                {t('Step 1')}:{' '}
                                {subject.type === 1
                                    ? t('Watch the video')
                                    : t('Run the course')}
                            </Heading3>
                            <VideoPlayer
                                logEvent={logEvent}
                                subject={subject}
                                videoTokenStore={videoTokenStore}
                                language={videoTokenStore.language}
                            />
                        </Fragment>
                    )}
                    {(subject.type === 2 || subject.type === 4) && (
                        <SCORMPlayer
                            training={training}
                            logEvent={logEvent}
                            subject={subject}
                        />
                    )}
                    {subject.type === 3 && (
                        <VideoPlayer
                            training={training}
                            logEvent={logEvent}
                            subject={subject}
                            onChange={onDecisionMade}
                            onLastDecision={onLastDecisionMade}
                            resetDecisions={() => setDecisionsCompleted(false)}
                            videoTokenStore={videoTokenStore}
                        />
                    )}
                    {!step2Available && questionsAvailable && (
                        <div className="quizHidden-holder">
                            <div className="quizOverlay">
                                <div className="image">
                                    <img
                                        src="/static/img/icon-display.svg"
                                        alt={t('Watch')}
                                    />
                                </div>
                                <div className="quizHiddenText">
                                    <p className="title">
                                        {t('Finish the video')}
                                    </p>
                                    <p>{t('to access the quiz')}</p>
                                </div>
                            </div>
                            <div className="quizHidden">
                                <FlexDiv py={16}>
                                    <Heading3 my={24}>
                                        {t('Step 2')}: {t('Complete the Quiz')}
                                    </Heading3>
                                </FlexDiv>
                                <Question
                                    question={subject.question}
                                    subject={subject}
                                    isAnswered={
                                        subject.status.ANSWARE_CORRECT === 1
                                    }
                                    answers={shuffledAnswers}
                                    logEvent={logEvent}
                                />
                            </div>
                        </div>
                    )}
                    {step2Available && questionsAvailable && (
                        <div ref={scrollRef}>
                            <Fragment>
                                <Heading3 displayBlock mt={40} mb={16}>
                                    {t('Step 2')}: {t('Complete the Quiz')}
                                </Heading3>
                                <Question
                                    question={subject.question}
                                    subject={subject}
                                    isAnswered={
                                        subject.status.ANSWARE_CORRECT === 1
                                    }
                                    answers={shuffledAnswers}
                                    logEvent={logEvent}
                                />
                                {subject.funfact && (
                                    <div className="section-fact">
                                        <Container
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeHtml(
                                                    subject.funfact
                                                ),
                                            }}
                                        ></Container>
                                    </div>
                                )}

                                {subject.content && (
                                    <div className="section-text">
                                        <Heading3 my={16} displayBlock>
                                            {t('Step 3')}:{' '}
                                            {t('Further reading')}
                                        </Heading3>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeHtml(
                                                    subject.content
                                                ),
                                            }}
                                        ></p>
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                )}
                            </Fragment>
                        </div>
                    )}
                    {decisionsCompleted && (
                        <FlexDiv
                            ref={scrollRef}
                            column
                            mx={24}
                            gap={24}
                            my={16}
                        >
                            <Heading4>
                                {numberOfRightAnswers}/
                                {subject.decision_longestPossiblePath}{' '}
                                {t('decisionSubjectFeedbackShortInfo', {
                                    count: subject.decision_longestPossiblePath,
                                })}
                            </Heading4>
                            <FlexDiv column={isMobile} gap={24}>
                                <DecisionFeedbackTable
                                    table={decisionFeedbackTable.slice(
                                        0,
                                        Math.round(
                                            subject.numberOfDecisions / 2
                                        )
                                    )}
                                />
                                <DecisionFeedbackTable
                                    table={decisionFeedbackTable.slice(
                                        Math.round(
                                            subject.numberOfDecisions / 2
                                        ),
                                        subject.numberOfDecisions
                                    )}
                                    indexOffset={Math.round(
                                        subject.numberOfDecisions / 2
                                    )}
                                />
                            </FlexDiv>
                            {subject.funfact && (
                                <div className="section-fact">
                                    <Container
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(
                                                subject.funfact
                                            ),
                                        }}
                                    ></Container>
                                </div>
                            )}
                        </FlexDiv>
                    )}
                </div>
            </div>
        );
    }
);
