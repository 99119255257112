import Auth from './AuthService';
import Users from './UsersService';
import Subjects from './SubjectsService';
import Trainings from './TrainingsService';
import Assessments from './AssessmentsService';
import Events from './EventsService';
import Companies from './CompaniesService';
import Branding from './BrandingService';
import LanguageService from './LanguageService';
import InfoSecService from './InfoSecService';
import AnalyticsService from './AnalyticsService';

export default {
    Auth: new Auth(),
    Users: new Users(),
    Subjects: new Subjects(),
    Trainings: new Trainings(),
    Events: new Events(),
    Companies: new Companies(),
    Branding: new Branding(),
    Languages: new LanguageService(),
    Assessments: new Assessments(),
    InfoSec: new InfoSecService(),
    Analytics: new AnalyticsService(),
};
