import React from 'react';
import {
    FlexDiv,
    Heading1,
    TextDefault,
    TextInput,
    ThemedButton,
    Image,
    palette,
} from '@awarego/awarego-components';
import { resourcePath } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';

function LandingPage({ onSubmit, onEmailChange, email, error, joining }) {
    const { brandingStore } = useStores();
    const { brandingLoaded, privacyUrl } = brandingStore;

    const cssMobile = `@media (max-width: 830px), (max-width: 390px) {
        .mobileViewHRA {
          align-items: center!important;
          width: auto;
          margin-left: 0
        }}
        
        @media (max-width: 390px) {
            .mobileViewHRA {
             text-align: center;
            }}
        `;

    return (
        <FlexDiv justifyCenter alignCenter mb={120}>
            <FlexDiv
                className="mobileViewHRA"
                alignStart
                justifyCenter
                column
                padding={24}
                ml={80}
                widthPercentage={50}
            >
                <style scoped>{cssMobile}</style>
                <TextDefault
                    bold
                    mb={24}
                    lineHeight={'125%'}
                    fontSizeRem={1.125}
                    style={{
                        fontFamily: 'EuclidCircularA-Medium, sans-serif',
                    }}
                >
                    Human Risk Assessment
                </TextDefault>

                <Heading1
                    mb={12}
                    lineHeight={'140%'}
                    fontSizeRem={2.75}
                    style={{
                        fontFamily: 'EuclidCircularA-Medium, sans-serif',
                    }}
                >
                    Test your cybersecurity <br />
                    knowledge and behavior
                </Heading1>
                <TextDefault
                    mb={60}
                    lineHeight={'125%'}
                    fontSizeRem={1.125}
                    color={palette.graphite.heavyMetal}
                    style={{
                        fontFamily: 'EuclidCircularA-Medium, sans-serif',
                    }}
                >
                    In less than 5 minutes
                </TextDefault>
                <form onSubmit={onSubmit} style={{ width: '80%' }}>
                    <FlexDiv column gap={error ? 0 : 8}>
                        <TextInput
                            placeholder={'Enter your email'}
                            stretched
                            value={email}
                            onChange={onEmailChange}
                            name="email"
                            error={error}
                            type="email"
                            helperText={error}
                            overwriteGlobalStyles
                        />
                        <ThemedButton
                            style={{
                                marginBottom: '80px',
                            }}
                            disabled={joining}
                            mode="primary"
                            size="fullWidth"
                            type="submit"
                            color={palette.vibrant.blue}
                            text="Start Assessment"
                        />
                        <TextDefault
                            style={{
                                color: palette.graphite.charcoal,
                                fontSize: '14px',
                            }}
                        >
                            By participating in this test you agree to receive
                            the occasional emails from us with information
                            regarding our service offerings. For further details
                            see our{' '}
                            <a
                                href={
                                    (brandingLoaded && privacyUrl) ||
                                    'https://awarego.com/privacy/'
                                }
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'underline' }}
                            >
                                Privacy Policy.
                            </a>
                        </TextDefault>
                    </FlexDiv>
                </form>
            </FlexDiv>
            <FlexDiv widthPercentage={41}>
                <Image
                    desktopOnly
                    src={resourcePath(`/static/img/mini-hra-landing.webp`)}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        height: 'auto',
                        width: '100%',
                    }}
                />
            </FlexDiv>
        </FlexDiv>
    );
}

export default LandingPage;
