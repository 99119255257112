import React, { useEffect, useState, Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, useHistory, Redirect } from 'react-router';
import Layout from './layouts/layout';
import TokenSignIn from './containers/TokenSignIn';
import DirectSignIn from './containers/DirectSignIn';
import { enforceSlash } from './utils/helpers';
import { EVENTS } from './utils/constants';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { I18nextProvider } from 'react-i18next';
import DirectionProvider, {
    DIRECTIONS,
} from 'react-with-direction/dist/DirectionProvider';
import * as Sentry from '@sentry/react';
import Infosec from './containers/infosec';
import cmi5 from './containers/cmi5/rxd';
import { useStores } from './hooks/use-stores';
import { useLocation, useRouteMatch } from 'react-router-dom';
import AppRouting from './containers/AppRouting';
import { ConfirmationModal } from '@awarego/awarego-components';
import Demo from './containers/demo';
import { Snackbar } from '@awarego/awarego-components';
import AuthRoute from './hocs/AuthRoute';
import SignIn from './containers/SignIn';
import CheckEmail from './containers/CheckEmail';
import EmailSignInError from './containers/EmailSignInError';
import SSO from './containers/SSO';
import PrivateRoute from './hocs/PrivateRoute';
import AssessmentResults from './containers/Assessments/AssessmentResults';
import { publicHRASubstrings } from './utils/constants';
import Waiting from './components/waiting';

function App() {
    const history = useHistory();

    const { store, companyStore, authStore, commonStore, brandingStore } =
        useStores();
    const { theme, muiTheme, brandingLoaded } = brandingStore;
    const {
        appLoading,
        languagesLoaded,
        i18n,
        messageShown,
        messageType,
        message,
    } = commonStore;
    const {
        isGuest,
        guestHomePath,
        company_id,
        subject_id,
        token,
        currentUser,
    } = authStore;
    const infosecMatch = useRouteMatch('/mini-hra');
    const location = useLocation();

    const miniHRAtype = useMemo(() => {
        const type = publicHRASubstrings.find((x) =>
            location.pathname.startsWith(`/${x}`)
        );
        brandingStore.setDefaultMiniHraType(type);
        return type;
    }, [location.pathname]);

    const { currentCompany } = companyStore;
    const [companyId, setCompanyId] = useState();

    const {
        confirmationRequired,
        confirmationDescription,
        confirmationAction,
        confirmationTitle,
        confirmationShowCancel,
        confirmationCancelText,
        additionalCheckbox,
        isConfirmationDelete,
    } = commonStore;

    useEffect(() => {
        if (
            companyId &&
            currentCompany &&
            companyId !== currentCompany.company_id
        ) {
            history.push('/');
        }
        setCompanyId(currentCompany && currentCompany.company_id);
    }, [companyId, currentCompany, history]);

    const trainingMatch = useRouteMatch(`/trainings/:trainingId`);

    useEffect(() => {
        if (currentCompany && currentUser) {
            //process utm when both company and user initialized
            const queryParams = new URLSearchParams(location.search);
            let utmSource, utmTerm;
            if (queryParams.has('utm_source')) {
                utmSource = queryParams.get('utm_source');
                queryParams.delete('utm_source');
            }
            if (queryParams.has('utm_term')) {
                utmTerm = queryParams.get('utm_term');
                queryParams.delete('utm_term');
            }
            if (utmSource || utmTerm) {
                const trainingId = trainingMatch
                    ? trainingMatch.params.trainingId
                    : queryParams.get('t');
                store.onLogEvent(trainingId, null, EVENTS.NOTIFICATION_LINK, {
                    utm_source: utmSource,
                    utm_term: utmTerm,
                });
                history.replace({
                    search: queryParams.toString(),
                });
            }
        }
    }, [currentCompany, currentUser, trainingMatch, history, store]);

    useEffect(() => {
        (async () => {
            if (token) {
                try {
                    await Promise.all([
                        authStore.pullUser(),
                        commonStore.loadLanguages(),
                    ]);
                } finally {
                    commonStore.setAppLoaded();
                }
            } else {
                await Promise.all([
                    brandingStore.loadBranding(),
                    commonStore.loadLanguages(),
                ]);
                await commonStore.setAppLoaded();
            }
        })();
    }, [authStore, brandingStore, commonStore, token]);

    const doConfirm = () => {
        commonStore.doConfirm();
    };

    const cancelConfirm = () => {
        commonStore.cancelConfirm();
    };

    const handleSignout = () => {
        authStore.logout();
    };
    const closeSnackbar = () => {
        commonStore.hideMessage();
    };

    const sendFeedback = async () => {
        const e = await Sentry.captureMessage('User Feedback Submitted');
        Sentry.withScope(function (scope) {
            scope.setTag('feedback', 'yes');
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video', Modernizr.video);
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video.h264', Modernizr.video.h264);
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video.ogg', Modernizr.video.ogg);
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video.vp9', Modernizr.video.vp9);
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video.webm', Modernizr.video.webm);
            // eslint-disable-next-line no-undef
            scope.setTag('modernizr.video.hls', Modernizr.video.hls);
            scope.setLevel('warning');
            Sentry.showReportDialog({
                labelSubmit: 'Send Feedback',
                eventId: e,
            });
        });
    };

    const switchLanguage = async (language_code) => {
        if (authStore.isAuthenticated) {
            await store.setLanguage(language_code);
        }
    };

    const getDirection = () => {
        const languageObject = commonStore.languages.find((obj) => {
            return obj.code === store.language;
        });

        if (languageObject && languageObject.rtl) {
            return DIRECTIONS.RTL;
        }
        return DIRECTIONS.LTR;
    };

    return (
        <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={theme}>
                {/*{!(languagesLoaded && brandingLoaded) && (*/}
                {/*    <Waiting waitingActive={appLoading} from={'App'} />*/}
                {/*)}*/}
                {languagesLoaded && brandingLoaded && (
                    <I18nextProvider i18n={i18n}>
                        <DirectionProvider direction={getDirection()}>
                            <Fragment>
                                <Route
                                    path="/"
                                    render={(props) => {
                                        let normalizedPath = enforceSlash(
                                            props.location.pathname.replace(
                                                /\/\//g,
                                                '/'
                                            )
                                        );
                                        if (
                                            commonStore.lastPage !==
                                            normalizedPath
                                        ) {
                                            commonStore.analyticsPageView(
                                                `${normalizedPath}`
                                            );
                                        }
                                        commonStore.lastPage = normalizedPath;
                                        return null;
                                    }}
                                />
                                <Switch>
                                    <Route
                                        path="/assessment/try-demo/:companyId/:reportTo"
                                        render={() => {
                                            return (
                                                <Demo isAdmin isAssessment />
                                            );
                                        }}
                                    />
                                    <Route
                                        path="/assessment/demo/:companyId/:reportTo"
                                        render={() => {
                                            return <Demo isAssessment />;
                                        }}
                                    />
                                    <Route
                                        path="/training/try-demo/:companyId/:reportTo"
                                        render={() => {
                                            return <Demo isAdmin isTraining />;
                                        }}
                                    />
                                    <Route
                                        path="/training/demo/:companyId/:reportTo"
                                        render={() => {
                                            return <Demo isTraining />;
                                        }}
                                    />
                                    <Layout
                                        sendFeedback={sendFeedback}
                                        handleSignout={handleSignout}
                                        switchLanguage={switchLanguage}
                                    >
                                        <Switch>
                                            <Route path="/infosec">
                                                <Redirect to="/mini-hra"></Redirect>
                                            </Route>
                                            <Route path="/human-risk-assessment">
                                                <Redirect to="/mini-hra"></Redirect>
                                            </Route>
                                            <Route path="/hra-demo">
                                                <Redirect to="/mini-hra"></Redirect>
                                            </Route>
                                            <Route
                                                path="/mini-hra"
                                                component={Infosec}
                                            />

                                            <Route
                                                path={`/assessment-results/:variant/:shareKey`}
                                                render={() => (
                                                    <AssessmentResults />
                                                )}
                                            />

                                            {publicHRASubstrings.map(
                                                (publicHRASubstring) => (
                                                    <Route
                                                        key={publicHRASubstring}
                                                        path={`/${publicHRASubstring}`}
                                                        render={() => (
                                                            <Infosec
                                                                variant={brandingStore.publicHRAVariant(
                                                                    publicHRASubstring
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                )
                                            )}

                                            <Route
                                                path="/auth/direct/signin/:token"
                                                exact
                                                component={DirectSignIn}
                                            />
                                            <Route
                                                path="/auth/jwt/signin/:token"
                                                exact
                                                component={TokenSignIn}
                                            />
                                            <Route
                                                path="/.well-known/security.txt"
                                                exact
                                            />
                                            <Route
                                                path="/cmi5/:companyId/course/:uniqueId/:accessKey/au/:auId"
                                                component={cmi5}
                                            />

                                            <AuthRoute
                                                path="/auth/signin"
                                                exact
                                                component={SignIn}
                                                company_id={company_id}
                                                subject_id={subject_id}
                                            />
                                            <AuthRoute
                                                path="/auth/check-email"
                                                exact
                                                component={CheckEmail}
                                            />
                                            <Route
                                                path="/auth/error/email"
                                                exact
                                                component={EmailSignInError}
                                            />
                                            <Route
                                                path="/sso"
                                                exact
                                                component={SSO}
                                            />
                                            <PrivateRoute
                                                render={() => {
                                                    if (
                                                        isGuest &&
                                                        !infosecMatch &&
                                                        !miniHRAtype
                                                    ) {
                                                        return (
                                                            <Redirect
                                                                to={
                                                                    guestHomePath
                                                                }
                                                            ></Redirect>
                                                        );
                                                    }
                                                    return (
                                                        <AppRouting
                                                            {...{
                                                                company_id,
                                                                subject_id,
                                                                currentCompany,
                                                                appLoading,
                                                                companyId,
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Switch>
                                    </Layout>
                                </Switch>
                                <Snackbar
                                    variant={messageType}
                                    message={message || ''}
                                    open={messageShown}
                                    onClose={closeSnackbar}
                                />
                                <ConfirmationModal
                                    confirmationRequired={confirmationRequired}
                                    confirmationDescription={
                                        confirmationDescription
                                    }
                                    confirmationAction={confirmationAction}
                                    confirmationTitle={confirmationTitle}
                                    confirmationShowCancel={
                                        confirmationShowCancel
                                    }
                                    confirmationCancelText={
                                        confirmationCancelText
                                    }
                                    additionalCheckbox={additionalCheckbox}
                                    isConfirmationDelete={isConfirmationDelete}
                                    cancelConfirm={cancelConfirm}
                                    doConfirm={doConfirm}
                                />
                            </Fragment>
                        </DirectionProvider>
                    </I18nextProvider>
                )}
            </ThemeProvider>
        </MuiThemeProvider>
    );
}

export default observer(App);
