import React from 'react';
import {
    FlexDiv,
    Heading1,
    TextDefault,
    ThemedButton,
} from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const Heading = styled(Heading1)`
    font-size: 28px;
`;

function InfosecFinish() {
    const { brandingStore } = useStores();
    const { brandingLoaded, miniHraFinishUrl } = brandingStore;
    return (
        <FlexDiv alignCenter column padding={24} fullWidth>
            <TextDefault bold mb={8} lighter>
                Human Risk Assessment
            </TextDefault>
            <Heading mb={4} center>
                Well done!
            </Heading>
            <TextDefault mb={16}>
                Click below to learn more about how you can assess human risk in
                your own organisation
            </TextDefault>
            <a
                href={
                    (brandingLoaded && miniHraFinishUrl) ||
                    'https://awarego.com/human-risk-assessment/'
                }
                target="_blank"
                rel="noreferrer"
            >
                <ThemedButton
                    mode="primary"
                    color="#3646D9"
                    text="Learn more about HRA"
                />
            </a>
        </FlexDiv>
    );
}

export default observer(InfosecFinish);
