import React from 'react';
import {
    FlexDiv,
    Heading4,
    TextInput,
    ThemedButton,
    palette,
    TextLead,
    TextTiny,
    Block,
} from '@awarego/awarego-components';
import styled, { css } from 'styled-components';
import { useStores } from '../../hooks/use-stores';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import animationData from './anim.json';
import { observer } from 'mobx-react';

function LandingPageShort({
    onSubmit,
    onEmailChange,
    email,
    error,
    joining,
    variant,
    emailOption,
}) {
    const { brandingStore } = useStores();
    const { logo, account_name, brandingLoaded, privacyUrl } = brandingStore;
    const { t } = useTranslation();

    const Logo = styled.div`
        img {
            max-height: 50px;
            max-width: 130px;
        }
        padding-bottom: 24px;
        position: relative;
        margin-right: 32px;
        display: flex;
        align-items: center;
        ${(props) =>
            props.noMargin &&
            css`
                margin: 0;
            `}
        cursor: pointer;
    `;

    const animationDefaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
        speed: 1,
    };

    const logoContainer = (
        <Link to="/">
            <img src={logo} alt={t('logoAlt', account_name)} />
        </Link>
    );

    return (
        <FlexDiv
            justifyCenter
            alignCenter
            style={{
                position: 'relative',
                width: '100%',
                height: '100vh',
            }}
        >
            <Lottie
                style={{ cursor: 'default' }}
                options={animationDefaultOptions}
                height={700}
                width={700}
            />

            <Block
                resetMargin
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '32px 24px 32px 24px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    background: '#fff',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    textAlign: 'start',
                }}
            >
                <Logo>{logoContainer}</Logo>
                <FlexDiv>
                    <TextLead color={palette.graphite.charcoal} mb={8}>
                        Test your cybersecurity knowledge and behavior{' '}
                    </TextLead>
                </FlexDiv>

                <form onSubmit={onSubmit}>
                    {emailOption !== 'no-email' && (
                        <>
                            <Heading4
                                fontSizeRem={1.5}
                                color={palette.graphite.heavyMetal}
                                style={{
                                    fontFamily:
                                        'EuclidCircularA-Regular, sans-serif',
                                    fontWeight: '500',
                                }}
                            >
                                {' '}
                                Enter your email{' '}
                                {emailOption === 'required-email'
                                    ? ''
                                    : '(optional)'}
                            </Heading4>
                            <FlexDiv mt={6} mb={6}>
                                <TextInput
                                    darkerInputBorder
                                    placeholder={'Email'}
                                    stretched
                                    value={email}
                                    onChange={onEmailChange}
                                    name="email"
                                    error={error}
                                    type="email"
                                    helperText={error}
                                    overwriteGlobalStyles
                                />
                            </FlexDiv>
                        </>
                    )}
                    <FlexDiv mb={12}>
                        <ThemedButton
                            style={{
                                marginBottom: '8px',
                                fontFamily:
                                    'EuclidCircularA-Regular, sans-serif',
                                fontWeight: '500',
                            }}
                            disabled={joining}
                            mode="primary"
                            size="fullWidth"
                            type="submit"
                            color={palette.vibrant.blue}
                            text="Let's go!"
                        />
                    </FlexDiv>
                </form>
                <TextTiny
                    color={palette.graphite.ash}
                    style={{ maxWidth: '330px' }}
                >
                    {brandingStore.privacyText(variant)}{' '}
                    <a
                        href={
                            (brandingLoaded && privacyUrl) ||
                            'https://awarego.com/privacy/'
                        }
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'underline' }}
                    >
                        Privacy Policy.
                    </a>{' '}
                </TextTiny>
            </Block>
        </FlexDiv>
    );
}

export default observer(LandingPageShort);
