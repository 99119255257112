import { Col, Row } from 'reactstrap';
import React from 'react';
import { Link } from 'react-router-dom';
import SemiCircleProgress from '../semi-circle-progress';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../hooks/use-stores';
import classes from 'classnames';
import { calculateColor, getScoreTitle } from '../../utils/helpers';
import { Heading2, ThemedButton } from '@awarego/awarego-components';
import { FacebookShareButton } from 'react-share';
import ShareIcon from '@material-ui/icons/Share';
export default observer(
    ({ parentUrl, assessment, trainingId, stars, shareResults, shareUrl }) => {
        const { t } = useTranslation();
        const { brandingStore } = useStores();

        const percentage =
            assessment.averageScore ||
            Math.max(
                0,
                Math.round((assessment.score / assessment.max_score) * 100)
            );

        const getScoreDescription = () => {
            let def = brandingStore.getScoreDefinition(percentage);
            if (def) return def.description;

            //fallback support
            return 'You have shown a very good understanding of topics presented in this training. With some additional training you will easily earn an Excellent.';
        };

        return (
            <div className="quiz-results block-shadow">
                <Row>
                    <Col md="4" xs="12">
                        <div
                            className={classes('progress-semicircle', {
                                'with-stars yellow': stars,
                            })}
                        >
                            <SemiCircleProgress
                                percentage={percentage.toFixed(0)}
                                size="large"
                            />
                        </div>
                    </Col>
                    <Col md="8" xs="12">
                        <div className="center">
                            <Heading2>
                                {t('Your score is')}:{' '}
                                <span
                                    style={{
                                        color: calculateColor(
                                            percentage,
                                            brandingStore
                                        ),
                                    }}
                                >
                                    {t(
                                        getScoreTitle(percentage, brandingStore)
                                    )}
                                </span>
                            </Heading2>
                            <p>{t(getScoreDescription())}</p>
                            {!shareResults && (
                                <Link
                                    to={`${parentUrl}${
                                        trainingId
                                            ? '/subjects/quiz/answers'
                                            : `/${assessment.id}/answers`
                                    }`}
                                >
                                    <ThemedButton
                                        mode="secondary"
                                        text={t('Review answers')}
                                    />
                                </Link>
                            )}
                            {!shareResults && assessment.shareKey && (
                                <FacebookShareButton
                                    url={shareUrl}
                                    quote={'My cyber security score'}
                                    hashtag="#awarego #icelandpolice"
                                >
                                    <ThemedButton
                                        mode="primary"
                                        text={t('Share challenge')}
                                        icon={<ShareIcon />}
                                        iconPosition="start"
                                        $noWrap={true}
                                    />
                                </FacebookShareButton>
                            )}
                        </div>
                    </Col>
                </Row>
                <br />
            </div>
        );
    }
);
